@import "../../../styles/base/abstracts";

.hbs-statistics-cta {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;

  @include mq($bp-desktop) {
    flex-direction: row;
  }
}

.hbs-statistics-cta__media {
  background-color: $c-bg;
  position: relative;
  flex: 1;

  .hbs-statistics-cta--image-right & {
    @include mq($bp-desktop) {
      order: 2;
    }
  }
}

.hbs-statistics-cta__content {
  flex: 1;
}

.hbs-statistics-cta__title {
  @include h3;
}

.hbs-statistics-cta__subtitle {
  margin-top: $spacing-lg;
}

.hbs-statistics-cta__cta {
  margin-top: $spacing-sm;
}

.hbs-statistics-cta__statistics {
  @include grid-parent($offset: true);
  position: relative;
  margin-top: $spacing-lg;

  .hbs-statistics-cta__list {
    background-color: $c-bg;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mq($bp-tablet) {
      @include border(top, $c-border-light, 1);
      flex-direction: row;
    }
  }

  .hbs-statistic {
    @include grid-child;
    @include stat-size(small);
    display: inline-flex;
    padding-bottom: $spacing-md;
    width: 100%;

    @include mq($bp-tablet) {
      flex-direction: column;
      width: 50%;
    }
  }

  .hbs-statistic__figure {
    border-top: $border-solid;
    padding-top: $spacing-md;
    transform: none;
    width: 100%;

    @include mq($bp-tablet) {
      border-top: none;
    }
  }

  .hbs-statistic__caption {
    align-items: flex-end;
    border-bottom: $border-solid;
    border-bottom-color: transparent;
    border-top: $border-solid;
    display: flex;
    padding-top: $spacing-md;
    width: 100%;

    @include mq($bp-tablet) {
      @include padding($spacing-xs x);
      align-items: baseline;
      border-bottom: $border-solid;
      border-top: none;
      height: 100%;
    }
  }

  .hbs-statistic__figure,
  .hbs-statistic__caption {
    @include mq($bp-tablet) {
      border: none;
    }
  }
}
