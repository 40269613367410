@mixin stat-size($size: small) {
  .hbs-statistic__figure {
    @if $size == "large" or $size == "xl" {
      @include giant($ff-serif, $fw-light, $visually-align: false);
      height: 1.3em;
    } @else {
      @include h1($ff-serif, $fw-light, $visually-align: false);
      height: 1.3em;
    }

    @if $size == "small" {
      @include mq($bp-desktop) {
        @include h2($ff-serif, $fw-light, $visually-align: false);
        height: 1.3em;
      }
    } @else if $size == "medium" {
      @include mq($bp-desktop) {
        @include h1($ff-serif, $font-weight: $fw-light, $visually-align: false);
        height: 1.3em;
      }
    } @else if $size == "large" {
      @include mq($bp-desktop) {
        @include giant(
          $ff-serif,
          $font-weight: $fw-light,
          $visually-align: false
        );
        height: 1.15em;
      }
    } @else if $size == "xl" {
      @include mq($bp-desktop) {
        @include ginormous($ff-serif, $visually-align: false);
        height: 1.05em;
      }
    }
  }
}
